import CardSectionWrapper from "Shared/components/wrapper/CardSectionWrapper";
import { FC } from "react";
import { ChartData, ChartOptions } from "chart.js/auto";
import LineChart from "Shared/components/chart/my-line-chart";
import { string } from "yup";
import { Icon } from "@iconify/react";
import { classNames, wrapClick } from "Shared/utils/ui";
import moment from "moment";
import AppConfig from "config";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

interface VerificationRequestRowProps {
  code: string;
  accountType: string;
  date: string | Date;
  id: string;
}

const mobileMoneyIcon = "ic:outline-smartphone";
const cashIcon = "ic:outline-attach-money";

const PaymentIcons = {
  MobileMoney: mobileMoneyIcon,
  Cash: cashIcon,
};

const VerificationRequestRow: FC<VerificationRequestRowProps> = ({
  accountType,
  code,
  date,
  id,
}) => {
  const navigate = useNavigate();

  return (
    <div className='relative group' onClick={() => navigate("/requests/" + id)}>
      <div className='py-[22px] px-[30px] border-b border-neutral-200 hover:bg-[#fcfcfc] cursor-pointer'>
        <div className='text-zinc-800 text-base font-semibold leading-relaxed mb-1'>
          {code}
        </div>
        <div className='flex items-center gap-x-2.5'>
          <p className='flex gap-1.5 items-center text-stone-500  text-sm font-medium  leading-relaxed'>
            {accountType}
          </p>
          <p className="text-neutral-400 text-sm font-normal font-['Nunito'] leading-snug"></p>
          <div className='w-px h-[11px] bg-neutral-200'></div>

          <div className="text-neutral-400 text-sm font-normal font-['Nunito'] leading-snug">
            Submitted On: {moment(date).format(AppConfig.date.format)}
          </div>
        </div>
      </div>
      <div className='absolute invoice-btn py-1.5 px-[15px] right-[30px] rounded text-[#666] text-[13px] cursor-pointer bg-[#EEE] transition-all duration-300 ease-in-out opacity-0 transform -translate-y-full group-hover:opacity-100 group-hover:translate-y-1/2 mt-3 top-0 hover:text-white hover:bg-[#333]'>
        View Request
      </div>
    </div>
  );
};

export default VerificationRequestRow;
