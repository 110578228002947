// import Header from "Shared/components/layout/header";
import useCookies from "Shared/hooks/cookies";
import { FC } from "react";
import OverviewCard from "./components/overview-card";
import CardSectionWrapper from "Shared/components/wrapper/CardSectionWrapper";
import VerificationsTrendCard from "./components/members-trend-card";
import NotificationsOverviewList from "./components/notification-overview";
import PaymentsOverviewList from "./components/payments-overview";
import Header from "Shared/components/layout/header";
import { useQuery } from "@tanstack/react-query";
import { getDashboardSummary } from "./duck/fetch";
import Loader from "Shared/components/suspense/loaders";
import numeral from "numeral";

const DashboardPage: FC = () => {
  const [user] = useCookies("user");
  const parsedUser = user ? JSON.parse(user) : null;

  const query = useQuery({
    queryKey: ["dashboard"],
    queryFn: () => getDashboardSummary(),
  });

  return (
    <section>
      <div className='p-2.5 mb-8'>
        {query.isLoading ? (
          <div className='min-h-[600px] w-full flex flex-1 items-center justify-center'>
            <Loader />
          </div>
        ) : query?.data?.data ? (
          <div className='space-y-[30px]'>
            {/* OVerview section */}
            <div className='grid grid-cols-4 gap-[30px]'>
              <OverviewCard
                bgColor='bg-amber-500'
                textColor='text-amber-500'
                icon='ic:outline-account-circle'
                title={"Total Testers"}
                value={query?.data?.data?.overview?.totalTesterUsers}
              />
              <OverviewCard
                bgColor='bg-pink-500'
                textColor='text-pink-500'
                icon='ic:outline-business-center'
                title={"Total Developers"}
                value={query?.data?.data?.overview?.totalDeveloperUsers}
              />
              <OverviewCard
                bgColor='bg-blue-500'
                textColor='text-blue-500'
                icon='ic:baseline-accessibility-new'
                title={"Total verification requests"}
                value={query?.data?.data?.overview?.totalVerificationRequests}
              />
              <OverviewCard
                bgColor='bg-green-500'
                textColor='text-green-500'
                icon='bi:person-check'
                title={"Total Verified Users"}
                value={query?.data?.data?.overview?.totalVerifiedCustomers}
              />
            </div>

            <div className='grid grid-cols-3 gap-x-[30px] items-stretch '>
              <div className='col-span-2 '>
                <VerificationsTrendCard
                  data={query?.data?.data?.verificationTrends}
                />
              </div>

              <div className='col-span-1 h-full'>
                <PaymentsOverviewList data={query?.data?.data?.verifications} />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </section>
  );
};

export default DashboardPage;
