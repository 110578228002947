import { FC, Fragment, useCallback, useEffect } from "react";
import { INavSection, NavSections } from "./data/navItems";
import SidebarNavItem from "./components/SidebarNavItem";
// import Divider from "../../../components/seperators/divider";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import useCookies from "../../hooks/cookies";
import { Menu, Transition } from "@headlessui/react";
import { wrapClick } from "../../utils/ui";

import { showToast } from "../../utils/alert";
import Logo from "Shared/components/brand/logo";
import { isValidJSON } from "Shared/utils/data-structures";
import { LogoIconImage } from "assets";
const Sidebar: FC = () => {
  const [user, setUser] = useCookies("user");
  const currentRoute = useLocation().pathname;
  const parsedUser = isValidJSON(user) ? JSON.parse(user) : {};

  return (
    <div className='w-full flex flex-col h-full overflow-auto border-r border-gray-200 bg-primary-700 items-stretch z-20'>
      {/* LOGO Area */}
      <div className='flex flex-shrink-0 items-center border-b border-primary-600 p-4 flex-row'>
        <div className='flex-shrink-0 h-10 w-10 p-1 bg-[#2A41E8]'>
          <img
            className='h-full w-full aspect-h-1 aspect-w-1 border-white border p-1'
            src={LogoIconImage}
            alt='SUBS'
          />
        </div>
        <div className='ml-3'>
          <p className='text-sm text-white font-mono'>
            Commercial Services Console
          </p>
          {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p> */}
        </div>
      </div>
      {/* NAV Area */}
      <div className='py-4 flex flex-grow flex-col overflow-y-auto no-scrollbar'>
        <ul className='flex-1 space-y-1.5 px-2' aria-label='Sidebar'>
          {NavSections.map((section, index) => (
            <ul>
              <Fragment key={section.title}>
                {section.items.map((item) => (
                  <SidebarNavItem
                    icon={item.icon}
                    label={item.label}
                    to={item.to}
                    activeRoutes={item?.activeRoutes}
                    notificationType={item.notificationType}
                    notificationsCount={item.notificationsCount}
                  />
                ))}
              </Fragment>
            </ul>
          ))}
        </ul>
      </div>
      <div className='flex-1'></div>
    </div>
  );
};

export default Sidebar;
