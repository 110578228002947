import { ChartBarSquareIcon, UsersIcon } from "@heroicons/react/24/outline";

interface INavItem {
  to: string;
  icon: any;
  label: string;
  notificationsCount?: number;
  notificationType?: "info" | "danger";
  activeRoutes?: string[];
}

export interface INavSection {
  title: string;
  items: INavItem[];
}
export const NavSections: INavSection[] = [
  {
    title: "Dashboard",
    items: [
      {
        to: "/",
        icon:ChartBarSquareIcon,
        label: "Overview",
      },
    ],
  },

  {
    title: "Verification",
    items: [
      {
        to: "/requests",
        icon:  UsersIcon,  // Consider changing the icon to something more relevant to verification if needed
        label: "Verification Requests",
      },
    ],
  }
];
