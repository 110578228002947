import { AxiosDataService } from "Shared/utils/axios"
import { IGetFilter } from "data/index.types"

export const doCreatePayment = async (values: any) => {
    return AxiosDataService.post('/api/payments', values)
}




export const getVerifications = async (filter: IGetFilter) => {
    return AxiosDataService.get(`/api/verifications?populate=createdBy`, filter)
}







export const getVerification = async (id: string, populate?: string | string[]) => {
    return AxiosDataService.get("/api/verifications/" + id, {
        populate: populate || ["createdBy",]

    })
}

export const getMiniStaffs = async () => {
    return AxiosDataService.get("/api/staff?select=name,id")
}

export const getPaymentsExportUrl = async (filter: IGetFilter) => {
    return AxiosDataService.get(`/api/payments/export?populate=createdBy`, filter)
}


export const doApproveVerification = async (params: { id: string, values: any }) => {
    return AxiosDataService.post(`/api/verifications/${params.id}/approve`, params.values)
}
export const doRejectVerification = async (params: { id: string, values: any }) => {
    return AxiosDataService.post(`/api/verifications/${params.id}/reject`, params.values)
}