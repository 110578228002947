import StatusChip, { StatusType } from "Shared/components/chips/status-chip";
import Avatar from "Shared/components/media/avatar";
import { wrapImage } from "Shared/utils/ui";
import AppConfig from "config";
import { VerificationDocumentTypes } from "data";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";

interface MemberViewProps {
  verification: any;
}
const VerificationView: FC<MemberViewProps> = ({ verification }) => {
  function getStatusType(data): StatusType {
    if (verification?.status == "Approved") return "success";
    if (verification?.status == "Rejected") return "danger";
    if (verification?.status === "Pending") return "warning";
  }

  return (
    <div className='space-y-6 mb-6'>
      <div className={" bg-white border rounded"}>
        <div className='border-b py-3 px-2  flex items-center gap-x-5'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            User Information
          </h2>
          <StatusChip
            type={getStatusType(verification)}
            info={verification?.status}
          />
        </div>
        <div
          className={
            "grid grid-cols-4 mb-4  dark:bg-[#242A38] dark:border-gray-600 gap-x-3 gap-y-6 py-4 px-4 "
          }
        >
          <div className='row-span-4 items-center justify-center flex flex-col space-y-2'>
            <Avatar
              alt={verification?.createdBy?.fullName}
              src={verification?.createdBy?.profileImageUrl || ""}
              size='lg'
            />
            <span className='block  font-medium  text-gray-700'>
              {verification?.accountType}
            </span>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>Code</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {verification?.createdBy?.code || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Full Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {verification?.createdBy?.fullName || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Country
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {verification?.createdBy?.country || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Created At
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {moment(verification?.dateOfBirth).format(AppConfig.date.format)}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {verification?.createdBy?.emailAddress || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {verification?.createdBy?.phoneNumber || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className={" bg-white border rounded"}>
        <div className='border-b py-3 px-2 flex items-center gap-x-5 '>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Identification Information
          </h2>
        </div>
        <div className=' border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
          <>
          
            <div className='pt-6'>
             

              <div className='grid grid-cols-6 gap-6 '>
                <div className='col-span-1'>
                  <span className='block text-sm   font-light text-gray-700'>
                   Photo
                  </span>
                  <Avatar
                    alt={verification?.createdBy?.fullName || "N A"}
                    src={
                      verification?.identificationInfo?.profileImageUrl || ""
                    }
                    size='lg'
                  />
                </div>
                <div className='col-start-1 col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Nationality
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {verification?.identificationInfo?.nationality || "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Date of Birth
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {moment(
                      verification?.identificationInfo?.dateOfBirth
                    ).format(AppConfig.date.format)}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {_.startCase(
                      verification?.identificationInfo?.identityCardType
                    ) || "N/A"}
                  </div>
                </div>
                <div className='col-span-2 col-start-1'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {verification?.identificationInfo?.identityCardNumber ||
                      "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Issue Date
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {moment(
                      verification?.identificationInfo?.identityCardIssueDate
                    ).format(AppConfig.date.format)}
                  </div>
                </div>
                {verification?.identificationInfo?.identityCardType !==
                  "VotersIdentificationCard" && (
                  <div className='col-span-2'>
                    <span className='block text-sm font-light text-gray-700'>
                      ID Expiry Date
                    </span>
                    <div className='mt-1 block w-full sm:text-sm'>
                      {moment(
                        verification?.identificationInfo?.identityCardExpiryDate
                      ).format(AppConfig.date.format)}
                    </div>
                  </div>
                )}

                <div className='col-span-3'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Front Image
                  </span>
                  <div className='mt-2'>
                    {wrapImage(
                      <img
                        src={
                          verification?.identificationInfo
                            ?.identityCardFrontImageUrl
                        }
                        alt={"front"}
                        className='w-full h-64 text-xs border  border-dashed rounded p-2'
                      />
                    )}
                  </div>
                </div>
                {!["DriversLicense", "VotersIdentificationCard"].includes(
                  verification?.identificationInfo?.identityCardType
                ) && (
                  <div className='col-span-3'>
                    <span className='block text-sm font-light text-gray-700'>
                      ID Back Image
                    </span>
                    <div className='mt-2'>
                      {wrapImage(
                        <img
                          src={
                            verification?.identificationInfo
                              ?.identityCardBackImageUrl
                          }
                          alt={"back"}
                          className='w-full h-64 text-xs border border-dashed rounded p-2'
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
      <div className={" bg-white border rounded"}>
        <div className='border-b py-3 px-2  flex items-center gap-x-5'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Verification Documents
          </h2>
        </div>
        {verification?.verificationDocuments[0]?.type ? (
          <table className='min-w-full divide-y divide-gray-300 border border-gray-300 border-t-0'>
            <thead className='bg-gray-50'>
              <tr className='divide-x divide-gray-200'>
                <th
                  scope='col'
                  className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 '
                ></th>
                <th
                  scope='col'
                  className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900'
                >
                  Selected Type
                </th>
                <th
                  scope='col'
                  className='whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 '
                >
                  Description
                </th>
                <th
                  scope='col'
                  className='whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900  text-left'
                >
                  Uploaded Document
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {_.map(
                verification?.verificationDocuments,
                (verificationDocument, idx) => {
                  const id = `materials[${idx}]`;
                  const isLast =
                    (verification?.verificationDocuments?.length || 0) === idx;
                  return (
                    <tr key={idx} className='divide-x divide-gray-200'>
                      <td className='py-2 whitespace-nowrap text-sm text-gray-800 font-medium dark:text-gray-400  px-3'>
                        {idx + 1 || "N/A"}
                      </td>
                      <td className='py-4 whitespace-nowrap text-sm text-gray-800 font-medium dark:text-gray-400  px-3'>
                        {VerificationDocumentTypes.find(
                          (item) => item.value == verificationDocument.type
                        ).name || "N/A"}
                      </td>
                      <td className='py-2 whitespace text-sm text-gray-800 font-light dark:text-gray-400  px-3  max-w-[40%]'>
                        {VerificationDocumentTypes.find(
                          (item) => item.value == verificationDocument.type
                        ).description || "N/A"}
                      </td>
                      <td className='px-3 text-left'>
                        <p className=' text-sm '>
                          <a
                            href={verificationDocument.uploadUrl}
                            target='_blank'
                            // download
                            rel='noreferrer'
                            className='whitespace-nowrap  text-primary-500 hover:text-primary-600'
                          >
                            Click Here to View
                            <span aria-hidden='true'> &rarr;</span>
                          </a>
                        </p>{" "}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        ) : (
          <div className='min-h-[300px] items-center justify-center flex border border-dashed border-gray-300'>
            <div className='text-center'>
              <svg
                className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-300'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  vectorEffect='non-scaling-stroke'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                />
              </svg>
              <h3 className='mt-2 text-sm font-medium text-gray-900 dark:text-gray-100'>
                No Verification Documents
              </h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerificationView;
