import { FC } from "react";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "Shared/utils/ui";

interface SidebarNavItemProps {
  icon: any;
  label: string;
  to: string;
  isCollapsed?: boolean;
  notificationsCount?: number;
  notificationType?: "info" | "danger";
  activeRoutes?: string[];
}
const SidebarNavItem: FC<SidebarNavItemProps> = ({
  label,
  to,
  notificationsCount,
  notificationType,
  isCollapsed,
  activeRoutes = [],
  ...props
}) => {
  const currentRoute = useLocation().pathname;
  const isActive = currentRoute === to || activeRoutes.includes(currentRoute);
  return (
    <li title={label}>
      <Link
        to={to}
        className={classNames(
          "group flex items-center px-2 py-2 text-sm rounded-md",
          isActive
            ? "bg-primary-600 text-white "
            : "text-gray-300 hover:bg-primary-800 hover:text-white"
        )}
      >
        <props.icon
          className={classNames(
            "text-gray-200 group-hover:text-gray-50",
            "mr-3 flex-shrink-0 h-6 w-6"
          )}
        />

        {label}
      </Link>
    </li>
  );
};

export default SidebarNavItem;
