import PrimaryButton from "Shared/components/buttons/primary-button";
import { TableComponent } from "Shared/components/tables";
import { wrapClick } from "Shared/utils/ui";
import { FC } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useUrlState from "Shared/hooks/use-url-state";
import useTableData from "Shared/hooks/use-table-data";
import ActionButton from "Shared/components/buttons/action-button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { formatAndShowAxiosError } from "Shared/utils/errors";
import moment from "moment";
import AppConfig from "config";
import YesNoDialog from "Shared/components/overlays/yes-no-dialog";
import CalendarPicker from "Shared/components/input/calendar-picker";
import useDownloadFile, {
  fileExtensionMappings,
} from "Shared/utils/use-download-file";
import toast from "react-hot-toast";
import { showToast } from "Shared/utils/alert";
import Avatar from "Shared/components/media/avatar";
import { getVerifications, getPaymentsExportUrl } from "./duck/fetch";
import AddPaymentContainer from "./add";
import StatusChip, { StatusType } from "Shared/components/chips/status-chip";
import ApproveVerificationContainer from "./approve";
import RejectVerificationContainer from "./reject";
import SearchSelectInput from "Shared/components/input/search-select-input";
const VerificationsListPage: FC = () => {
  const navigate = useNavigate();
  const [page] = useUrlState<number>("page", 1);
  const [pageSize] = useUrlState<number>(
    "pageSize",
    AppConfig.constants.pageSize
  );
  const [search, setSearch] = useUrlState<string>("search", "");
  const [fromDate] = useUrlState<any>("fromDate");
  const [toDate] = useUrlState<any>("toDate");
  const [exportType, setExportType] = useUrlState<string>("exportType");
  const [current, setCurrent] = useUrlState("current");
  const [searchParams, setSearchParams] = useSearchParams();
  const [modal, setModal] = useUrlState("modal");
  const [status, setStatus] = useUrlState<string>("status");
  const [accountType, setAccountType] = useUrlState<string>("accountType");

  const dispatchAction = (id: string, action: "delete" | "add") => {
    if (action) searchParams.set("modal", action);
    if (id) searchParams.set("current", id);

    setSearchParams(searchParams);
  };

  const {
    data: queryData,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: [
      "verifications",
      page,
      pageSize,
      search,
      fromDate,
      toDate,
      accountType,
      status,
    ],
    queryFn: () =>
      getVerifications({
        page,
        pageSize,
        search,
        fromDate,
        status: status,
        accountType: accountType,
        toDate,
        searchFields: ["code"],
      }),
    throwOnError: (error: AxiosError | any) => {
      formatAndShowAxiosError(error);
      return false;
    },
  });

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () =>
      new Date().toISOString() +
      `_Payments.${fileExtensionMappings?.[exportType]}`,
  });

  function getStatusType(data): StatusType {
    if (data?.status == "Approved") return "success";
    if (data?.status == "Rejected") return "danger";
    if (data?.status === "Pending") return "warning";
  }

  function getStatusText(data) {
    if (data?.status == "Approved") return "Approved";
    if (data?.status == "Rejected") return "Rejected";
    if (data?.status === "Pending") return "Pending";
  }

  const {
    data: exportData,
    isPending: exportLoading,
    mutate: createDataExport,
  } = useMutation({
    mutationKey: ["payments-export", fromDate, toDate, exportType],
    mutationFn: (exportType: string) =>
      getPaymentsExportUrl({
        fromDate,
        toDate,
        status,
        reason: "Withdrawal",
        exportType: exportType,
      }),
    onSuccess: (response) => {
      setExportType(undefined);
      if (response?.data) {
        showToast({
          type: "success",
          title: "File exported successfully",
        });
        downloadAction(`${response?.data}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    },
    onError: (error) => formatAndShowAxiosError(error),
  });

  const data = useTableData({
    rows: queryData?.data?.rows || [],
    count: queryData?.data?.total || 0,
  });
  return (
    <div>
      {/* <YesNoDialog
        open={modal === "delete"}
        setOpen={(val: boolean) => setModal(val ? "delete" : undefined)}
        title={"Are you sure ?"}
        onYesClicked={() => {}}
        // yesLoading={}
        onNoTapped={() => {}}
      >
        <div className='text-xs py-3'>
          <span>Are you sure you want to delete staff ?</span>
        </div>
      </YesNoDialog> */}

      <h3
        className={
          "font-firmaBold text-lg my-2 text-gray-600 flex items-center"
        }
      >
        <div className={"h-2 w-2 bg-yellow-500  rounded-full mr-1"} /> Filter
      </h3>
      <div className={"grid gap-x-2 gap-y-2 grid-cols-3"}>
        <div>
          <CalendarPicker label='Date' />
        </div>
        <div className='col-start-1'>
          <SearchSelectInput
            id='status'
            label='Status'
            options={[
              { label: { title: "Pending" }, value: "Pending" },
              { label: { title: "Approved" }, value: "Approved" },
              { label: { title: "Rejected" }, value: "Rejected" },
            ]}
            placeholder='Select status'
            isFilter={true}
            required={false}
            values={{ status }}
            setFieldValue={(field: string, value: any) => {
              setStatus(value);
            }}
          />
        </div>
        <div className=''>
          <SearchSelectInput
            id='accountType'
            label='Account Type'
            options={[
              { label: { title: "Starter" }, value: "Starter" },
              { label: { title: "Professional" }, value: "Professional" },
            ]}
            placeholder='Select accountType'
            isFilter={true}
            required={false}
            values={{ accountType }}
            setFieldValue={(field: string, value: any) => {
              setAccountType(value);
            }}
          />
        </div>
      </div>

      <h3
        className={
          "text-lg flex justify-between items-center text-gray-600 mt-14 mb-1 font-firmaBold"
        }
      >
        <span className={"font-firmaBold text-lg my-2 flex items-center"}>
          <div className={"h-2 w-2 bg-yellow-500 rounded-full mr-1"} />
          Verification Requests
        </span>
      </h3>

      <TableComponent
        refetch={refetch}
        onExportClicked={(value) => {
          setExportType(value);
          createDataExport(value);
        }}
        title='Verifications'
        isRefetching={isRefetching}
        loading={isLoading}
        data={data}
        hideExportButtons={true}
        exportDownloading={
          exportLoading ? (exportType as "excel" | "csv" | "pdf") : undefined
        }
        columns={[
          "#",
          "Code",
          "Account Type",
          "Tester Name",
          "Tester Email",
          "Date",
          "Last Updated",
          "Status",
          "",
        ]}
        loaders={[
          "single",
          "single",
          "single",
          "single",
          "single",
          "single",
          "single",
          "single",
          "action",
        ]}
        fields={[
          (item, idx) => <td className='px-4 text-center'>{idx + 1}</td>,
          "code",
          "accountType",
          "createdBy.fullName",
          "createdBy.emailAddress",
          (item, idx) => (
            <td className='px-4 text-center border'>
              {moment(item?.createdAt).format("DD/MM/YYYY")}
            </td>
          ),
          (item, idx) => (
            <td className='px-4 text-center border'>
              {item?.updatedAt
                ? moment(item?.updatedAt).format("DD/MM/YYYY")
                : "N/A"}
            </td>
          ),
          (item, idx) => (
            <td className='px-4 text-center border'>
              <StatusChip
                type={getStatusType(item)}
                info={getStatusText(item)}
              />
            </td>
          ),
          (item, idx) => (
            <div className='flex gap-x-2 items-center py-4 px-4 justify-center'>
              <ActionButton
                action='view'
                className='!bg-black'
                iconClassName='!text-white'
                tooltip='View verification request details'
                onClick={() => navigate("/requests/" + item?._id)}
              />
            </div>
          ),
        ]}
      />
    </div>
  );
};

export default VerificationsListPage;
