import { Navigate, RouteObject } from "react-router-dom";
import Register from "Modules/Auth/Register";
import AppLayout from "Shared/layout";
import LoginPage from "Modules/Auth/Login";
import VerificationsListPage from "Modules/Requests";
import DashboardPage from "Modules/Dashboard";
import ViewVerificationPage from "Modules/Requests/view";

const routes = (isAuth): RouteObject[] => [
  {
    path: "/*",
    element: !isAuth ? <Navigate to={"/login"} /> : <AppLayout />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "requests",
        element: <VerificationsListPage />,
      },
      {
        path: "requests/:id",
        element: <ViewVerificationPage />,
      },
    ],
  },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },

  {
    path: "/login",
    element: isAuth ? <Navigate to={"/app"} /> : <LoginPage />,
  },
  // {
  //   path: "/forgot-password",
  //   element: isAuth ? <Navigate to={"/app"} /> : <ForgotPassword />,
  // },
  // {
  //   path: "/register",
  //   element: isAuth ? <Navigate to={"/app"} /> : <Register />,
  // },
  // {
  //   path: "/test",
  //   element: <MemberFormPrintable />,
  // },
  {
    path: "*",
    element: <Navigate to={isAuth ? "/" : "/login"} />,
  },
];

export default routes;
